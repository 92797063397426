import model from './model';
import { ExpandModeMainWidgetEvents as Events } from '../../constants/generalConstants';

export default model.createController(({ $w, $bind, $widget, flowAPI }) => {
  $widget.onPropsChanged((_, newProps) => {
    const { items, currentIndex } = newProps;
    if (items?.length > 0 && currentIndex >= 0) {
      $w('#expandWidgetSlot').slot.items = items;
      $w('#expandWidgetSlot').slot.currentIndex = currentIndex;
      $w('#expandWidgetSlot').slot.onCloseExpandModeClicked(() => {
        $widget.fireEvent(Events.CloseExpandModeClicked, {});
      });
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
