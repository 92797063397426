import model from './model';
import { ExpandModeMainWidgetEvents as Events } from '../../constants/generalConstants';

const defaultState = { currentIndex: -1, items: '[]' };

export default model.createController(
  ({ initState, $w, $bind, $widget, flowAPI }) => {
    const { state } = initState<{
      currentIndex: number;
      items: string;
    }>(defaultState);

    const initWidget = async () => {
      $w('#close').onClick(() => {
        $widget.fireEvent(Events.CloseExpandModeClicked, {});
      });
      $w('#buttonPrev').onClick(() => {
        if (state.currentIndex > 0) {
          state.currentIndex = state.currentIndex - 1;
        }
      });
      $w('#buttonNext').onClick(() => {
        if (state.currentIndex < state.items.length - 1) {
          state.currentIndex = state.currentIndex + 1;
        }
      });
    };

    $widget.onPropsChanged((_, newProps) => {
      const { items, currentIndex } = newProps;
      if (items?.length > 0 && currentIndex >= 0) {
        state.currentIndex = currentIndex;
        state.items = JSON.stringify(items);
      }
    });

    return {
      pageReady: async () => {
        initWidget();
        $bind('#proGallery1', {
          items: () => {
            const stateItems = JSON.parse(state.items);
            if (state.currentIndex >= 0) {
              const indexItem = stateItems[state.currentIndex];
              return [indexItem];
            } else {
              return [];
            }
          },
        });
        $bind('#text1', {
          text: () => {
            const stateItems = JSON.parse(state.items);
            return stateItems[state.currentIndex]?.title || '';
          },
        });
      },
      exports: {},
    };
  },
);
