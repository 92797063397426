export type WarmupDataManagerType = {
  warmupData: WarmupData;
  environment: Environment;
  manageData: (callback: any, key: string) => any;
};
export class WarmupDataManager {
  warmupData: WarmupData;
  environment: Environment;

  constructor(
    warmupData: WarmupData,
    environment: Environment,
  ) {
    this.warmupData = warmupData;
    this.environment = environment;
    this.manageData = this.manageData.bind(this);
  }
  async manageData(callback: any, key: string) {
    let res;
    if (!this.environment.isSSR) {
      res = this.warmupData.get(key);
    }
    if (!res) {
      res = callback();
      if (this.environment.isSSR) {
        res.then((result: any) => {
          this.warmupData.set(key, result);
        });
      }
    }
    return res;
  }
}
