import { FastGalleryService } from '../../services/fastGalleryService';
import {
  convertDraftItemToWixCodeItem,
  convertDraftItemsToSEOItems,
} from '../../utils/mediaConverters';
import { WarmupDataManager } from '../../utils/WarmupDataManager';
import { getViewMode } from '../../utils/utils';
import { widgetElements } from './config/constants';
import model from './model';
import { DraftItem } from '@wix/ambassador-fastgallery-draftitem-v1-draft-item/types';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  const warmupData = new WarmupDataManager(
    flowAPI.controllerConfig.wixCodeApi.window.warmupData,
    flowAPI.environment,
  );
  let itemsResponse: DraftItem[];
  const initGallery = async () => {
    // @ts-expect-error
    const { galleryId } = flowAPI.controllerConfig.config;
    const fastGalleryService = new FastGalleryService({
      viewMode: getViewMode(flowAPI),
      draftGalleryId: galleryId,
      httpClient: flowAPI.httpClient,
      warmupData,
    });

    itemsResponse = await fastGalleryService.getGalleryItemsInCurrentContext();

    const wixSDKitems = convertDraftItemToWixCodeItem(itemsResponse, flowAPI);

    // @ts-expect-error
    $w(widgetElements.fastGallery).items = wixSDKitems;
    // @ts-expect-error
    $w(widgetElements.fastGallery).onItemClicked((event) => {
      const { itemIndex } = event;

      $w(widgetElements.expandModeWidget).currentIndex = itemIndex;
      // @ts-expect-error
      $w(widgetElements.fastGallery).setExpandOpen(true);
    });
    $w(widgetElements.expandModeWidget).items = wixSDKitems;

    $w(widgetElements.expandModeWidget).onCloseExpandModeClicked(() => {
      // @ts-expect-error
      $w(widgetElements.fastGallery).setExpandOpen(false);
    });
  };

  const renderSEOTags = () => {
    const items = convertDraftItemsToSEOItems(itemsResponse);
    flowAPI.controllerConfig.wixCodeApi.seo.renderSEOTags({
      itemType: 'MEDIA_COMPONENT',
      itemData: { items },
    });
  };
  return {
    pageReady: async () => {
      await initGallery();
      if (flowAPI.experiments.enabled('specs.fastGallery.renderSEOTags')) {
        renderSEOTags();
      }
    },
    exports: {},
  };
});
