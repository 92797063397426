export const widgetElements = {
  mainGallery: '#proGallery1', // Main gallery - everything runs based on it's state
  backgroundGallery: '#proGallery2',
  navigationGallery: '#proGallery3',
  title: '#text1', // A text component that we will use to display the title in our projects data
  paragraph: '#text2',
  buttonPrev: '#buttonPrev',
  buttonNext: '#buttonNext',
  counter: '#text5',
  close: '#button1',
} as const;

export const transparentSrc =
  'wix:image://v1/a9ff3b_648eebce0b3e49f693bc85649d10bbd8~mv2.png/transparent.png#originWidth=416&originHeight=233';
