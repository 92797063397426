import { Item } from '@wix/ambassador-fastgallery-item-v1-item/types';
import {
  MediaManagerItem,
  OrganizeMediaItem,
  OrganizeMediaItemPhoto,
} from '../types/organizedMediaTypes';
import {
  DraftItem,
  MediaItemType,
} from '@wix/ambassador-fastgallery-draftitem-v1-draft-item/types';
import _ from 'lodash';
import {
  convertDraftItemLinkToOrganizeMedia,
  convertOrganizeMediaToDraftItemLink,
  getWixCodeLink,
} from './linksUtils';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const convertMediaManagerItemsToDraftItems = (
  mediaArray: MediaManagerItem[],
  draftGalleryId: string,
): DraftItem[] => {
  return mediaArray.flatMap((item: MediaManagerItem) => {
    if (item.mediaType === 'picture') {
      return {
        draftGalleryId,
        title: item?.title,
        link: {},
        type: MediaItemType.IMAGE,
        media: {
          imageOptions: {
            focalPoint: {
              x: 0.5,
              y: 0.5,
            },
            imageInfo: {
              id: item.id || '',
              width: item.width,
              height: item.height,
              filename: item.fileName || '',
            },
          },
          type: MediaItemType.IMAGE,
        },
      } as DraftItem;
    } else {
      return [];
    }
  });
};

export const convertMediaManagerItemsOrganizeMediaItems = (
  mediaArray: MediaManagerItem[],
): OrganizeMediaItem[] => {
  return mediaArray.flatMap((item: MediaManagerItem) => {
    if (item.mediaType === 'picture') {
      const fileName = item.fileName;
      const width = item.width;
      const height = item.height;
      return {
        dataType: 'Photo',
        id: item.id,
        mediaUrl: fileName
          ? `https://static.wixstatic.com/media/${fileName}`
          : '',
        fileName,
        name: item.title,
        photoMetadata: {
          width,
          height,
          focalPoint: {
            x: 0.5,
            y: 0.5,
          },
        },
        title: item.title,
        metaData: {
          width: item.width,
          height: item.height,
          fileName,
          name: item.title,
        },
        alt: '',
        link: {},
      } as OrganizeMediaItemPhoto;
    } else {
      return [];
    }
  });
};

export const convertDraftItemsToOrganizeMediaItems = (
  mediaArray: DraftItem[],
): OrganizeMediaItem[] => {
  return mediaArray.flatMap((item: DraftItem) => {
    if (item.media?.type === MediaItemType.IMAGE) {
      const fileName = item.media.imageOptions?.imageInfo?.filename;
      const width = item.media.imageOptions?.imageInfo?.width;
      const height = item.media.imageOptions?.imageInfo?.height;
      return {
        dataType: 'Photo',
        id: item.id,
        mediaUrl: fileName
          ? `https://static.wixstatic.com/media/${fileName}`
          : '',
        fileName,
        name: item.title,
        photoMetadata: {
          width,
          height,
          focalPoint: {
            x: item.media.imageOptions?.focalPoint?.x || 0.5,
            y: item.media.imageOptions?.focalPoint?.y || 0.5,
          },
        },
        title: item.title,
        metaData: {
          width,
          height,
          fileName,
          name: item.title,
        },
        alt: item.media.imageOptions?.imageInfo?.altText,
        link: convertDraftItemLinkToOrganizeMedia(item.link),
        orderIndex: item.sortOrder || -new Date()?.getTime(),
        revision: item.revision,
      } as OrganizeMediaItemPhoto;
    } else {
      return [];
    }
  });
};

export const convertOrganizeMediaItemsToDraftItems = (
  mediaArray: OrganizeMediaItem[],
  draftGalleryId: string,
): DraftItem[] => {
  return mediaArray.flatMap((item: OrganizeMediaItem) => {
    if (item.dataType === 'Photo') {
      return {
        id: item.id,
        draftGalleryId,
        title: item?.title,
        link: !_.isEmpty(item.link)
          ? convertOrganizeMediaToDraftItemLink(item.link)
          : null,
        sortOrder: item.orderIndex,
        type: MediaItemType.IMAGE,
        media: {
          imageOptions: {
            secure: item.isSecure,
            focalPoint: {
              x: item?.photoMetadata?.focalPoint?.x || 0.5,
              y: item?.photoMetadata?.focalPoint?.y || 0.5,
            },
            imageInfo: {
              id: item.fileName || '',
              filename: item.fileName,
              width: item.photoMetadata?.width,
              height: item.photoMetadata?.height,
              altText: item?.alt,
              url: item.mediaUrl,
            },
          },
          type: MediaItemType.IMAGE,
        },
        revision: item?.revision,
      } as DraftItem;
    } else {
      return [];
    }
  });
};

export const convertDraftItemToWixCodeItem = (
  mediaArray: DraftItem[] | Item[] = [],
  flowAPI: PlatformControllerFlowAPI,
) => {
  return mediaArray.flatMap((item: DraftItem) => ({
    title: item?.title,
    description: '',
    alt: item?.media?.imageOptions?.imageInfo?.altText || '',
    src: calculateImageUrl(item?.media?.imageOptions?.imageInfo),
    type: item?.media?.type || 'image',
    settings: {
      focalPoint: [
        item?.media?.imageOptions?.focalPoint?.x,
        item?.media?.imageOptions?.focalPoint?.y,
      ],
    },
    link: getWixCodeLink(flowAPI, item?.link),
  }));
};

export const convertDraftItemsToSEOItems = (mediaArray: DraftItem[] = []) => {
  return mediaArray.map((item: DraftItem) => ({
    id: item.id,
    url: calculateImageUrl(item?.media?.imageOptions?.imageInfo),
    height: item.media?.imageOptions?.imageInfo?.height || 0,
    width: item.media?.imageOptions?.imageInfo?.width || 0,
    altText: item.media?.imageOptions?.imageInfo?.altText || '',
  }));
};

const calculateImageUrl = (imageInfo) => {
  if (!imageInfo || !imageInfo.filename) {
    return '';
  } else {
    const { filename, width, height } = imageInfo;
    return `wix:image://v1/${filename}/${filename}#originWidth=${width}&originHeight=${height}`;
  }
};
