import {
  Link,
  WixLink,
  Type as DraftItemType,
} from '@wix/ambassador-fastgallery-item-v1-item/types';
import { ILinkType, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import _ from 'lodash';
import {
  getOrganizeMediaLinkType,
  defaultLinkText,
  getDraftItemLinkFieldOptions,
} from '../components/fastGalleryWidget/panels/helpers/organizeMediaGeneralUtils';
import { LINK_PANEL_LINK_TYPES } from '../constants/organizeMediaConstants';
import { OrganizeMediaItemLink } from '../types/organizedMediaTypes';

export const convertDraftItemLinkToOrganizeMedia = (
  link?: Link,
): OrganizeMediaItemLink | object => {
  if (!link?.wixLinkData) {
    return {};
  }
  const linkType = Object.keys(link.wixLinkData)[0];
  const data = {
    ...link.wixLinkData[linkType],
    type: getOrganizeMediaLinkType(linkType),
  };
  return {
    type: 'wix',
    value: defaultLinkText({ type: 'wix', data }),
    data,
  };
};

export const convertOrganizeMediaToDraftItemLink = (
  link?: OrganizeMediaItemLink,
): Link => {
  if (_.isEmpty(link)) {
    return {};
  }
  const linkData = link?.data;
  const draftItemLink = linkData
    ? getDraftItemLinkFieldOptions(linkData)
    : null;

  if (!draftItemLink || !linkData) {
    return {};
  }
  // TODO: remove when server make it optional
  if (linkData.type === LINK_PANEL_LINK_TYPES.DOCUMENT_LINK) {
    draftItemLink.wixLinkData.indexable = false;
  }

  return {
    target: linkData?.target || '_blank',

    wixLinkData: {
      [draftItemLink.type]: draftItemLink.wixLinkData,
    },
    text: link?.text || null,
    type: draftItemLink.isInternal
      ? DraftItemType.INTERNAL
      : DraftItemType.EXTERNAL,
    url: draftItemLink?.url ? draftItemLink.url : null,
  };
};

const convertWixLinkToILinkType = (wixLinkData: WixLink): ILinkType | void => {
  const wixLinkType = Object.keys(wixLinkData)[0];
  switch (wixLinkType) {
    case 'page':
      return {
        type: 'PageLink',
        pageId: wixLinkData?.page?.pageId || '',
      };

    case 'external':
      return {
        type: 'ExternalLink',
        url: wixLinkData?.external?.url || '',
        target: (wixLinkData?.external?.target as any) || '_blank',
      };

    case 'anchor':
      return {
        type: 'AnchorLink',
        anchorName: wixLinkData?.anchor?.anchorName || '',
        anchorDataId: wixLinkData?.anchor?.anchorDataId || '',
        pageId: wixLinkData?.anchor?.pageId || '',
      };

    case 'dynamicPage':
      return {
        type: 'DynamicPageLink',
        routerId: wixLinkData?.dynamicPage?.routerId || '',
        innerRoute: wixLinkData?.dynamicPage?.innerRoute || '',
      };

    case 'document':
      return {
        type: 'DocumentLink',
        docId: wixLinkData?.document?.docId || '',
        name: wixLinkData?.document?.name || '',
        url: `https://example.com/docs/${wixLinkData?.document?.docId}`, // Example URL format
      };

    case 'email':
      return {
        type: 'EmailLink',
        recipient: wixLinkData?.email?.recipient || '',
        subject: wixLinkData?.email?.subject || '',
      };

    case 'phone':
      return {
        type: 'PhoneLink',
        phoneNumber: wixLinkData?.phone?.phoneNumber || '',
      };
  }
};

const getExternalLink = (link: Link, flowAPI: PlatformControllerFlowAPI) => {
  const wixLinkData = link?.wixLinkData;
  if (!wixLinkData) {
    return;
  }

  const iLinkObj = convertWixLinkToILinkType(wixLinkData);
  if (!iLinkObj) {
    return;
  }
  const url = flowAPI.controllerConfig.platformAPIs.links?.toUrl(iLinkObj);
  return {
    // TODO: when server fix support for tel & mail in links remove url & convertWixLinkToILinkType object
    url: link?.url || url,
    target: link?.target || '_target',
  };
};

const getInternalLink = (link: Link, flowAPI: PlatformControllerFlowAPI) => {
  const wixLinkData = link?.wixLinkData;
  if (!wixLinkData) {
    return;
  }

  const iLinkObj = convertWixLinkToILinkType(wixLinkData);
  if (!iLinkObj) {
    return;
  }
  const url = flowAPI.controllerConfig.platformAPIs.links?.toUrl(iLinkObj);
  const baseUrl = flowAPI.controllerConfig.wixCodeApi.location.baseUrl;
  return {
    // TODO: when server fix support for tel & mail in links remove url & convertWixLinkToILinkType object
    url: baseUrl + url,
    target: link?.target || '_self',
  };
};

export const getWixCodeLink = (
  flowAPI: PlatformControllerFlowAPI,
  link?: Link,
) => {
  if (!link) {
    return;
  }
  const linkType = link?.type;

  switch (linkType) {
    case DraftItemType.INTERNAL:
      return getInternalLink(link, flowAPI);
    case DraftItemType.EXTERNAL:
      return getExternalLink(link, flowAPI);
  }
};
