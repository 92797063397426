import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ViewMode } from '../types/viewMode';

export const getViewMode = (flowAPI: PlatformControllerFlowAPI): ViewMode => {
  let viewMode: ViewMode = 'SITE';
  const { isEditor, isPreview } = flowAPI.environment;
  if (isEditor) {
    viewMode = 'EDITOR';
  } else if (isPreview) {
    viewMode = 'PREVIEW';
  }
  return viewMode;
};

export function getStringFromNumber(num: number) {
  if (num < 10) {
    return '0' + num;
  } else {
    return num;
  }
}

export const getSelectorFromName = (name) => {
  return name.includes('#') ? name : `#${name}`;
};
